
























































































































































































































































































































































































































































































































































































@import "../assets/scss/_v-calendar.scss";
.choose-option{
  &-wr{
    display: flex;
  }
  width: 20px;
  height: 20px;
  margin-right: 4px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all .34s ease-out;
  input{
    display: none;
  }
  span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 0px 1.5px #5E4BF1;
    border-radius: 50%;
    display: none;
  }
  input:checked + span{
    display: inline-block;
  }
}
.choose-option:hover{
  opacity: 0.8;
}
.dropdown-user{
  &-list{
    display: flex;
    align-items: center;
    .image-wr{
      display: inline-block;
      position: relative;
      flex: 0 0 22px;
      margin-right: 6px;
    }
    .image{
      display: block;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      img{
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .name{
      white-space: nowrap;
      font-size: 14px;
    }
    .st-online{
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #02BB9F;
      position: absolute;
      bottom: -1px;
      right: -1px;
    }
  }
  .list{
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -6px;
    padding-right: 6px;
  }
  &-btn{
    border-radius: 6px;
    padding: 4px 8px;
    background: transparent;
    cursor: pointer;
    transition: all .34s ease-out;
  }
  &-unass{
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: #767676;
    .icon{
      display: inline-block;
      width: 16px;
      line-height: 0;
      margin-right: 3px;
      img{
        max-width: 18px;
      }
    }
    .fill{
      fill: #767676;
      transition: all .34s ease-out;
    }
  }
}
.dropdown-user.is-active{
  .dropdown-user-btn{
    background: rgba(94, 75, 241, 0.12);
    .dropdown-user-unass{
      color: #5E4BF1;
    }
    .fill{
      fill: #5E4BF1;
    }
  }
}
.dropdown-user-btn:hover{
  background: rgba(94, 75, 241, 0.12);
  .dropdown-user-unass{
    color: #5E4BF1;
  }
  .fill{
    fill: #5E4BF1;
  }
}
.gr{
  &-wrapper{
    display: flex;
    align-items: center;
  }
  &-label{
    width: 170px;
    margin-right: 10px;
  }
}
.app-timeline-wrapper{
  .fc .fc-timeline-now-indicator-arrow{
    display: none;
  }
  .fc .fc-timeline-now-indicator-line{
    border-width: 0 0 0 2px;
    transform: translateX(23px);
    border-color: #FA665B;
    &:after{
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: #FA665B;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-5px);
    }
  }
  .fc-timeline-event{
    border-radius: 8px;
    height: 44px;
    position: relative;
    bottom: -5px!important;
    padding: 4px 16px;
  }
  .fc-event-main{
    > span{
      display: block;
      width: 100%;
      line-height: 1.2em;
    }
  }
  .evetn-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title{
      color: #161616;
      font-size: 14px;
    }
    .user{
      display: flex;
      align-items: center;
      line-height: 1em;
    }
    .image{
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 8px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name{
      color: #161616;
      font-size: 12px;
      margin-bottom: 1px;
    }
    .prof{
      color: #767676;
      font-size: 12px;
    }
  }
  .day-week{
    color: #767676;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2px;
    font-weight: 400;
  }
  .day-month{
    width: 22px;
    height: 22px;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
    line-height: 23px;
    font-size: 12px;
    font-weight: 400;
  }
  .fc-timeline-slot-label.fc-day-today{
    .day-month{
      background: #FA665B;
      color: #FFFFFF;
    }
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-scrollgrid-section-header,
  .fc-scrollgrid-section-body{
    > td:last-of-type{
      border-right: 0;
    }
  }
  .fc-scrollgrid-section-body{
    > td:first-of-type .fc-scroller.fc-scroller-liquid-absolute{
      overflow: hidden!important;
    }
  }
  .fc-resource-timeline-divider.fc-cell-shaded{
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
  .fc-datagrid-cell-frame{
    display: flex;
    align-items: center;
  }
  .fc-scroller-harness{
    .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner{
      width: 100%;
    }
  }
}

