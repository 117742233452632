.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  height: 420px;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart-emoji {
  padding: 6px;
  position: relative;
  display: inline-block;
  font-size: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji span {
  display: inline-block;
}

.emoji-mart-preview-emoji .emoji-mart-emoji span {
  width: 38px;
  height: 38px;
  font-size: 32px;
}

.emoji-type-native {
  font-family: 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI',
    'Apple Color Emoji', 'Twemoji Mozilla', 'Noto Color Emoji', 'EmojiOne Color',
    'Android Emoji';
  word-break: keep-all;
}

.emoji-type-image {
  /* Emoji sheet has 56 columns, see also utils/emoji-data.js, SHEET_COLUMNS variable */
  /* Here we use (56+1) * 100% to avoid visible edges of nearby icons when scaling for different
   * screen sizes */
  background-size: 5700%;
}
.emoji-type-image.emoji-set-apple {
  background-image: url("https://unpkg.com/emoji-datasource-apple@6.0.1/img/apple/sheets-256/64.png");
}
.emoji-type-image.emoji-set-facebook {
  background-image: url("https://unpkg.com/emoji-datasource-facebook@6.0.1/img/facebook/sheets-256/64.png");
}
.emoji-type-image.emoji-set-google {
  background-image: url("https://unpkg.com/emoji-datasource-google@6.0.1/img/google/sheets-256/64.png");
}
.emoji-type-image.emoji-set-twitter {
  background-image: url("https://unpkg.com/emoji-datasource-twitter@6.0.1/img/twitter/sheets-256/64.png");
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}
.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-scroll {
  position: relative;
  overflow-y: scroll;
  flex: 1;
  padding: 0 6px 6px 6px;
  z-index: 0; /* Fix for rendering sticky positioned category labels on Chrome */
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
  -webkit-overflow-scrolling: touch;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  color: #858585;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  border: none;
  background: none;
  box-shadow: none;
}
.emoji-mart-anchor:hover,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg {
  fill: currentColor;
  max-height: 18px;
}

.emoji-mart .scroller {
  height: 250px;
  position: relative;
  flex: 1;
  padding: 0 6px 6px 6px;
  z-index: 0; /* Fix for rendering sticky positioned category labels on Chrome */
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
  -webkit-overflow-scrolling: touch;
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
}
.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 0.2em 0.6em;
  border-radius: 25px;
  border: 1px solid #d9d9d9;
  outline: 0;
}
.emoji-mart-search-results {
  height: 250px;
  overflow-y: scroll;
}

.emoji-mart-category {
  position: relative;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before,
.emoji-mart-emoji-selected:before {
  z-index: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
  opacity: 0;
}
.emoji-mart-category .emoji-mart-emoji:hover:before,
.emoji-mart-emoji-selected:before {
  opacity: 1;
}

.emoji-mart-category-label {
  position: sticky;
  top: 0;
}
.emoji-mart-static .emoji-mart-category-label {
  z-index: 2;
  position: relative;
  /* position: sticky; */
  /* position: -webkit-sticky; */
}

.emoji-mart-category-label h3 {
  display: block;
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}
.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}
.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}
.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}
.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999a9c;
  font-size: 21px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches-opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches-opened .emoji-mart-skin-swatch-selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}
.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}
.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}
.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}
.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}
.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch-selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}
.emoji-mart-skin-swatch-selected:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}
.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}
.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}
.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}
.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}
.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* vue-virtual-scroller/dist/vue-virtual-scroller.css */
.emoji-mart .vue-recycle-scroller {
  position: relative;
}
.emoji-mart .vue-recycle-scroller.direction-vertical:not(.page-mode) {
  overflow-y: auto;
}
.emoji-mart .vue-recycle-scroller.direction-horizontal:not(.page-mode) {
  overflow-x: auto;
}
.emoji-mart .vue-recycle-scroller.direction-horizontal {
  display: flex;
}
.emoji-mart .vue-recycle-scroller__slot {
  flex: auto 0 0;
}
.emoji-mart .vue-recycle-scroller__item-wrapper {
  flex: 1;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.emoji-mart .vue-recycle-scroller.ready .vue-recycle-scroller__item-view {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}
.emoji-mart
  .vue-recycle-scroller.direction-vertical
  .vue-recycle-scroller__item-wrapper {
  width: 100%;
}
.emoji-mart
  .vue-recycle-scroller.direction-horizontal
  .vue-recycle-scroller__item-wrapper {
  height: 100%;
}
.emoji-mart
  .vue-recycle-scroller.ready.direction-vertical
  .vue-recycle-scroller__item-view {
  width: 100%;
}
.emoji-mart
  .vue-recycle-scroller.ready.direction-horizontal
  .vue-recycle-scroller__item-view {
  height: 100%;
}
.emoji-mart .resize-observer[data-v-b329ee4c] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  pointer-events: none;
  display: block;
  overflow: hidden;
  opacity: 0;
}
.emoji-mart .resize-observer[data-v-b329ee4c] object {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.emoji-mart-search .hidden {
  display: none;
  visibility: hidden;
}
