
















.block-layout{
  &_scroll{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 12px;
    margin: 0 -12px;
  }
}
