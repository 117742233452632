/*==========  goals-other Style  ==========*/
.new-task{
  &_wrapper{
    position: relative;
  }
  &_overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 28;
    display: none;
  }
  &_settings{
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.08);
    position: absolute;
    z-index: 32;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    transition: all 0.34s ease-out;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    font-size: 14px;
  }
  &_input{
    position: relative;
  }
  &_wrapper{
    &.active{
      .new-task_input{
        z-index: 32;
      }
      .new-task_settings{
        visibility: visible;
        opacity: 1;
        transform: none;
      }
      .new-task_overlay{
        display: block;
      }
    }
  }
}
.settings-task{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &:last-child{
    margin-bottom: 0;
  }
  .label{
    flex: 0 0 120px;
    margin-right: 10px;
  }
}
.settings-drop{
  position: relative;
  &_inner{
    position: relative;
    cursor: pointer;
    transition: all .34s ease-out;
  }
  &_overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 34;
    display: none;
  }
  &_list{
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
    position: absolute;
    z-index: 34;
    top: calc(100% + 8px);
    left: 0;
    min-width: 210px;
    transition: all 0.34s ease-out;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    font-size: 14px;
    li {
      display: block;
      padding: 5px 12px;
      border-radius: 8px;
      margin-bottom: 4px;
      cursor: pointer;
      transition: all .34s ease-out;
      &:last-child{
        margin-bottom: 0;
      }
      &.is-active{
        background-color: rgba(94, 75, 241, 0.12)!important;
      }
    }
  }
  &_btn{
    position: relative;
    font-size: 14px;
    font-weight: 500;
    padding-left: 20px;
    .icon{
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 16px;
      line-height: 0;
      img{
        width: 100%;
      }
    }
  }
  &.active{
    .settings-drop_overlay{
      display: block;
    }
    .settings-drop_list{
      visibility: visible;
      opacity: 1;
      transform: none;
    }
  }
}
.settings-drop_inner{
  opacity: 0.8;
}
.date-min-width{
  display: inline-block;
  min-width: 130px;
  text-align: center;
}
.goals-item{
  margin-bottom: 8px;
  &:last-of-type{
    margin-bottom: 0;
  }
  .checkbox-entry span{
    font-size: 16px;
    line-height: 20px;
  }
}
.accordion-item{
  .accordion-header{
    display: flex;
    > span {
      margin-right: 6px;
    }
  }
  &.type-two{
    .accordion-inner{
      padding-left: 8px;
      padding-top: 16px;
    }
    .btn-accordion{
      width: 20px;
      height: 20px;
      margin-left: 0;
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .settings-drop_list li:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

// @media (max-width: 575px)



