



































































































































































































































































































.editor{
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 28;
    display: none;
  }
  &-menu{
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    display: inline-block;
    min-width: 220px;
    position: fixed;
    display: none;
    li{
      padding: 4px;
      border-radius: 8px;
      margin-bottom: 4px;
      color: #161616;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .34s ease-out;
      &.is-active{
        background-color: rgba(0, 0, 0, 0.04);
      }
      &.active{
        background-color: rgba(0, 0, 0, 0.04);
      }
      &.menu-hr{
        margin-bottom: 8px;
        position: relative;
        &:after{
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          top: calc(100% + 4px);
          background-color: rgba(0, 0, 0, 0.08);
        }
      }
    }
    .icon{
      display: inline-block;
      width: 24px;
      line-height: 0;
      margin-right: 8px;
      img{
        width: 100%;
      }
    }
  }
  &-wrapper{
    display: block;
    &.is-active{
      .editor-menu{
        display: block;
        z-index: 6;
      }
      .editor-overlay{
        display: block;
        z-index: 4;
      }
    }
  }
}
@media (min-width: 1200px){
  .editor-menu li:hover{
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.ProseMirror > p{
  user-select: none;
}
.ProseMirror {
  min-height: 400px;
  outline: none;
  color: #161616;
  border-radius: 6px;
  > * + * {
    margin-top: 15px;
  }
  ul{
    list-style: disc;
  }
  ol{
    list-style: decimal;
  }
  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        line-height: 0;
        margin-bottom: -1px;
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: 'Type “/” to format or add block';
  float: left;
  color: #6F6F6F;
  pointer-events: none;
  height: 0;
}
