





















































































































































































































































































































































































































































































































































































































































































































































@import "../assets/scss/_variables.scss";
@import "../assets/scss/_header.scss";
.card-wrapper-negative{
  margin-left: -8px;
  margin-right: -8px;
}
.pagination{
  display: inline-block;
  padding: 0 65px;
  position: relative;
  .next,
  .prev{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .next{
    right: 0;
  }
  .prev{
    left: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 5px;
    &:last-child{
      margin-right: 0;
    }
    &.is-active{
      pointer-events: none;
    }
  }
}
.thumbnail-video{
  padding-bottom: 100%;
  width: 300px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  .bg{
    background-position: center;
  }
  .icon{
    width: 64px;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img{
      max-width: 100%;
    }
  }
}
.reviews{
  &-item{
    padding: 24px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 8px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &-header{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .image{
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      margin-right: 12px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 60%;
    }
    .time{
      margin-left: auto;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .rating{
      display: flex;
      align-items: center;
      margin-left: 12px;
      .icon{
        display: inline-block;
        line-height: 0;
        width: 24px;
        img{
          max-width: 100%;
        }
      }
    }
  }
}
.list-media{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0 5px 32px;
  position: relative;
  margin-bottom: 6px;
  &:last-child{
    margin-bottom: 0;
  }
  .title{
    margin-right: 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .info{
    white-space: nowrap;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1 0 100px;
    text-align: right;
  }
  .icon{
    display: inline-block;
    width: 24px;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -12px;
    img{
      width: 100%;
    }
  }
}
.custom-grid{
  &-item{
    display: flex;
    margin-bottom: 8px;
    &:last-child{
      margin-bottom: 0;
    }
    .label{
      min-width: 160px;
      padding-right: 16px;
    }
  }
}
.accordion-slide-hd-inner{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .title{
    margin-right: 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .info{
    white-space: nowrap;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1 0 180px;
    text-align: right;
  }
}
.accordion-content-inner{
  padding: 16px;
}
