












































































































































































































































































































































.tabs-container{
  &.full-height.is-active{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
.chart-wrapper{
  padding: 0 80px;
}
