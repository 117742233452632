






















































































































































































































.blur-content{
  filter: blur(10px);
  pointer-events: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.users-list_scroll{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2px 4px;
  margin: 0 -4px;
}
